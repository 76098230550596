import request from '@/common/request'
import { commonParams } from '@/api/config'
import { getToken } from '@/common/auth'

export function GetUserInfo() {
  const loginKey = getToken()
  return request({
    url: './query/GetUserInfo',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey
    })
  })
}

export function ModifyPlatformTitle(title) {
  const loginKey = getToken()
  return request({
    url: './modify/ModifyPlatformTitle',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      title
    })
  })
}

export function ModifyLogo(filePath) {
  const loginKey = getToken()
  return request({
    url: './modify/ModifyLogo',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      filePath
    })
  })
}

export function ModifyAllSubTitleAndLogo(useTitle, useLogo) {
  const loginKey = getToken()
  return request({
    url: './modify/ModifyAllSubTitleAndLogo',
    method: 'post',
    timeout: 60000,
    data: Object.assign({}, commonParams, {
      loginKey,
      useTitle,
      useLogo
    })
  })
}

export function ModifyEmailSwitch(MailNotification) {
  const loginKey = getToken()
  return request({
    url: './modify/ModifyEmailSwitch',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      MailNotification
    })
  })
}
